<template>
  <!-- 代理企业绑定地区 -->
  <div class="container">
    <a-page-header
      :title="title"
      :sub-title="'企业名称：' + name"
      @back="() => $router.go(-1)"
    />

    <div class="main-content">
      <div class="body">
        <a-steps :current="stepsCurrent">
          <a-step
            v-for="item in steps"
            :key="item.title"
            :title="item.title"
            :description="item.description"
          />
        </a-steps>

        <!-- 绑定地区-start -->
        <a-form-model
          ref="bindRuleForm"
          :model="bindForm"
          :rules="bindRules"
          :label-col="{ span: 9 }"
          :wrapper-col="{ span: 6 }"
          v-show="stepsCurrent === 0"
        >
          <a-form-model-item label="企业名称">
            <a-input :value="name" disabled />
          </a-form-model-item>
          <a-form-model-item label="选择地区" prop="areaId">
            <a-cascader
              :options="cascaderAreaOptions"
              placeholder="请选择地区"
              expand-trigger="hover"
              :field-names="fieldNames"
              v-model="bindForm.areaId"
            />
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              class="submit-btn"
              type="primary"
              :loading="bindLoading"
              @click="onBindSubmit"
            >
              确认绑定地区
            </a-button>
          </a-form-model-item>
        </a-form-model>
        <!-- 绑定地区-end -->
        <!-- 建立登录账号-start -->
        <a-form-model
          ref="userRuleForm"
          :model="userForm"
          :rules="userRules"
          :label-col="{ span: 9 }"
          :wrapper-col="{ span: 6 }"
          v-show="stepsCurrent === 1"
        >
          <a-form-model-item ref="username" label="使用者姓名" prop="username">
            <a-input
              v-model="userForm.username"
              placeholder="请输入使用者姓名"
              @blur="
                () => {
                  $refs.username.onFieldBlur()
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item label="备注">
            <a-input v-model="userForm.remark" placeholder="请输入备注" />
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              class="submit-btn"
              type="primary"
              :loading="addUserLoading"
              @click="onAddUserSubmit"
            >
              确认新增账号
            </a-button>
          </a-form-model-item>
        </a-form-model>
        <!-- 建立登录账号-end -->
        <!-- 绑定微信号-start -->
        <a-form-model
          :label-col="{ span: 9 }"
          :wrapper-col="{ span: 6 }"
          v-show="stepsCurrent === 2"
        >
          <div class="bind-status" v-if="qrcodeScanStatu">
            <div class="icon-box">
              <a-icon
                theme="filled"
                :style="{ color: '#FF4D4F', fontSize: '70px' }"
                type="info-circle"
              />
            </div>
            <div class="bind-title">已绑定微信，账号待开通</div>
            <div class="bind-p">请在联系公司后台负责人开通账号</div>
            <div class="btn-box">
              <a-button class="bind-btn" @click="$router.go(-2)">
                返回企业详情
              </a-button>
            </div>
          </div>
          <template v-else>
            <a-form-model-item label="系统账号" class="form-model-item">
              <a-input :value="num" disabled />
            </a-form-model-item>
            <div class="qrcode-box">
              <div class="qrcode">
                <iframe
                  id="iframe"
                  :src="wechatLink"
                  width="300px"
                  height="400px"
                  frameborder="0"
                  name="iframea"
                ></iframe>
              </div>
            </div>
          </template>
        </a-form-model>
        <!-- 绑定微信号-end -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: '',
      name: '',
      stepsCurrent: 0,
      steps: [
        {
          title: '绑定地区',
          description: '选择企业代理的地区'
        },
        {
          title: '建立登录账号',
          description: '输入账号持有人信息'
        },
        {
          title: '绑定微信号',
          description: '用于中台的账号登录'
        }
      ],
      bindForm: {
        // 绑定地区
        id: '',
        areaId: undefined
      },
      bindRules: {
        // 绑定地区
        areaId: [
          { required: true, message: '请选择选择地区', trigger: 'change' }
        ]
      },
      bindLoading: false,
      userForm: {
        // 建立登录账号
        pareaId: '',
        username: undefined
      },
      userRules: {
        // 上门
        username: [
          { required: true, message: '请输入使用者姓名', trigger: 'blur' }
        ]
      },
      addUserLoading: false,
      cascaderAreaOptions: [],
      fieldNames: {
        label: 'name',
        value: 'id',
        children: 'children'
      },
      num: '',
      wechatLink: '',
      qrcodeScanStatu: false
    }
  },
  created() {
    this.title = this.$getPageTitle()
    const query = this.$route.query
    if (query.code) {
      // 绑定微信
      this.stepsCurrent = 2
      const params = {
        num: query.num,
        code: query.code
      }
      this.agentEnterpriseAccountBindWX(params)
    } else if (query.status) {
      // 绑定成功
      this.stepsCurrent = 2
      this.qrcodeScanStatu = true
      const name = window.sessionStorage.getItem('agentEnterpriseName')
      if (name) {
        this.name = name
      }
    } else {
      // 常规
      this.bindForm.id = query.id
      this.name = query.name
      window.sessionStorage.setItem('agentEnterpriseName', query.name)
      this.getAgentEnterpriseArea()
    }
  },
  methods: {
    async getAgentEnterpriseArea() {
      // 获取区域列表
      const area = await this.$axios.getAgentEnterpriseArea()
      this.cascaderAreaOptions = area.nested
    },
    onBindSubmit() {
      // 提交绑定地区
      this.$refs.bindRuleForm.validate((valid) => {
        if (valid) {
          this.bindLoading = true
          const hide = this.$message.loading('请稍后', 0)
          const data = {
            id: this.bindForm.id,
            areaId: this.bindForm.areaId[this.bindForm.areaId.length - 1]
          }
          this.$axios
            .agentEnterpriseBindArea(data)
            .then((res) => {
              this.userForm.pareaId = res.data.data.pareaId
              this.stepsCurrent++
              this.bindLoading = false
              hide()
            })
            .catch(() => {
              this.bindLoading = false
              hide()
            })
        }
      })
    },
    onAddUserSubmit() {
      // 新增账户
      this.$refs.userRuleForm.validate((valid) => {
        if (valid) {
          this.addUserLoading = true
          const hide = this.$message.loading('请稍后', 0)
          this.$axios.agentEnterpriseAddAccount(this.userForm).then((res) => {
            const num = res.data.data.num
            this.num = num
            const subData = {
              num: num
            }
            // 获取绑定微信二维码
            this.$axios
              .getAgentEnterpriseAccountBindWXLink(subData)
              .then((subRes) => {
                this.wechatLink = subRes.data.data.url
                this.stepsCurrent++
                this.addUserLoading = false
                hide()
              })
          })
        }
      })
    },
    agentEnterpriseAccountBindWX(data) {
      // 绑定微信
      const hide = this.$message.loading('请稍后', 0)
      this.$axios.agentEnterpriseAccountBindWX(data).then(() => {
        hide()
        this.qrcodeScanStatu = true
        this.$router.replace({
          query: {
            status: 1
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.body {
  background-color: #fff;
  padding: 48px 160px;
}

.record-box {
  height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border: 1px solid #f0f2f5;
  margin-top: 16px;
}

.record-box .timer {
  color: rgba(37, 37, 37, 0.65);
}

.record-box .ant-btn:nth-child(2) {
  margin-left: 16px;
}

.ant-form {
  margin-top: 40px;
}

.ant-form .ant-calendar-picker {
  width: 100%;
}

.submit-btn {
  margin-left: 36em;
}

.form-model-item {
  margin: 0;
}

.qrcode-box {
  height: 400px;
  position: relative;
}

.qrcode {
  position: absolute;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
}

.bind-status {
  width: 560px;
  margin: 40px auto 0 auto;
}

.bind-status .icon-box {
  width: 70px;
  height: 70px;
  margin: 30px auto;
}

.bind-title {
  text-align: center;
  font-size: 24px;
  line-height: 34px;
  font-weight: bold;
}

.bind-p {
  text-align: center;
  font-size: 14px;
  height: 22px;
  margin: 8px auto 24px auto;
}

.btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-box .bind-btn:not(:last-child) {
  margin-right: 8px;
}
</style>
